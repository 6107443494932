<div class="flex">
  <div class="pr-3 flex">
    <div
      class="image_container"
      [ngStyle]="{
        'height.px': size,
        'width.px': size,
        'background-image': 'url(' + src + '), url(' + defaultSrc + ')'
      }"
      [ngClass]="{
        image_container__empty: !src,
        'rounded-full': rounded
      }"></div>
  </div>
  <div
    class="flex flex-col justify-center overflow-hidden"
    [ngClass]="{
      'gap-0.5': size === '60',
      'gap-2': size === '80',
      'gap-6': size === '120'
    }">
    @if (title) {
      <span
        class="font-bold line-clamp-1 text-ellipsis leading-9"
        [ngClass]="{
          'text-xl': size === '60',
          'text-2xl': size === '80',
          'text-4xl': size === '120',
          disabled: disabled
        }">
        {{ title }}
      </span>
    }
    @if (subtitle) {
      <span
        class="font-bold line-clamp-1 text-ellipsis"
        [ngClass]="{
          'text-sm': size === '60',
          'text-base': size === '80',
          'text-2xl': size === '120',
          disabled: disabled
        }">
        {{ subtitle }}
      </span>
    }
    @if (description) {
      <span
        class="description line-clamp-1 text-ellipsis font-bold"
        [ngClass]="{
          'text-sm': size === '60',
          'text-base': size === '80',
          'text-2xl': size === '120',
          disabled: disabled
        }">
        {{ description }}
      </span>
    }
  </div>
</div>
