<div class="grid grid-cols-12 md:gap-4">
  <div class="col-span-12 relative">
    <img
      alt="School Background"
      class="h-full w-full rounded-[0.75rem] md:rounded-[1.35rem] object-center object-cover md:brightness-50"
      [src]="getBackgroundUrl(school)" />
    @if (isOwner) {
      <app-edit-button
        [routerLink]="['/schools', school.id, 'edit', 'background']"
        class="absolute bottom-1 right-1 md:bottom-3 md:right-4"></app-edit-button>
    }
    <div class="flex absolute -bottom-8 left-4 md:bottom-8 md:left-8">
      <img
        [ngClass]="{ hidden: hideAvatar }"
        alt="School Avatar"
        class="w-[90px] md:w-[160px] h-[90px] md:h-[160px] rounded-xl md:rounded-[2.5rem]"
        [src]="getAvatarUrl(school)" />
      @if (isOwner) {
        <app-edit-button
          [routerLink]="['/schools', school.id, 'edit', 'avatar']"
          class="absolute -bottom-2.5 left-16 md:left-32 md:-bottom-3 z-10"></app-edit-button>
      }
      <div [ngClass]="{ 'pl-8': !hideAvatar }" class="md:flex w-full hidden">
        <div
          class="flex w-full justify-center md:justify-normal flex-col pt-4 self-end">
          <div class="flex gap-4">
            <strong class="text-[2.125rem] leading-[50px] text-white font-bold">
              {{ school.name }}
            </strong>
            @if (isOwner) {
              <app-edit-button
                [routerLink]="[
                  '/schools',
                  school.id,
                  'edit',
                  'name'
                ]"></app-edit-button>
            }
          </div>
          @if (school.type) {
            <app-school-type-tag
              class="pb-2"
              [type]="school.type!"></app-school-type-tag>
          }
          <span class="text-base line-clamp-1 text-ellipsis text-white">
            {{ getSchoolLevelsTitle(school) }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="flex flex-col col-span-12 md:col-start-3 md:col-span-9 relative md:hidden">
    <div
      class="flex flex-col items-center md:items-start m d:pl-[270px] pt-16 md:pt-3">
      <div class="flex w-full md:hidden">
        <div
          class="flex w-full justify-center md:justify-normal flex-col pt-4 self-end">
          <div class="flex gap-4">
            <strong class="text-[2.125rem] leading-[50px] font-bold">
              {{ school.name }}
            </strong>
            @if (isOwner) {
              <app-edit-button
                [routerLink]="[
                  '/schools',
                  school.id,
                  'edit',
                  'name'
                ]"></app-edit-button>
            }
          </div>
          @if (school.type) {
            <app-school-type-tag
              class="pt-2 pb-2"
              [type]="school.type!"></app-school-type-tag>
          }
          <span class="text-base line-clamp-1 text-ellipsis">
            {{ getSchoolLevelsTitle(school) }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="col-span-12 pt-6 md:pt-4 max-w-full overflow-hidden flex md:hidden">
    <div class="flex w-full overflow-y-scroll box-content gap-4 hide-scrollbar">
      @for (location of locations; track location.id; let i = $index) {
        <div class="flex h-12">
          <mat-chip
            class="location-chip self-center"
            [ngClass]="{ selected: location.id === school.id }"
            [routerLink]="['/schools', location.id]">
            {{ location.name }}
          </mat-chip>
          @if (i === 0) {
            <div
              class="self-center border-l solid border-black ml-4 h-12"></div>
          }
        </div>
      }
    </div>
  </div>

  <div
    class="col-span-12 pt-6 md:pt-4 max-w-full overflow-hidden hidden md:flex">
    <drag-scroll
      [scrollbar-hidden]="true"
      class="flex w-full overflow-y-scroll box-content hide-scrollbar overflow-hidden">
      @for (location of locations; track location.id; let i = $index) {
        <div drag-scroll-item class="flex h-12 px-2">
          <mat-chip
            class="location-chip self-center"
            [ngClass]="{ selected: location.id === school.id }"
            [routerLink]="['/schools', location.id]">
            {{ location.name }}
          </mat-chip>
          @if (i === 0) {
            <div
              class="self-center border-l solid border-black ml-4 h-12"></div>
          }
        </div>
      }
    </drag-scroll>
  </div>
</div>
