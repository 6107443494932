import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];
  private currentUrl$: Subject<string> = new BehaviorSubject<string>('');

  private readonly router = inject(Router);
  private readonly location = inject(Location);

  public startSaveHistory(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const [last] = [...this.history].reverse();
        const add = last !== event.urlAfterRedirects;
        if (add) this.history.push(event.urlAfterRedirects);
        this.currentUrl$.next(event.url.substring(1));
      }
    });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public async goBack(): Promise<void> {
    this.history.pop();

    if (this.history.length) {
      this.location.back();
    } else {
      await this.router.navigateByUrl('/');
    }
  }

  public getPrevUrl(): string | null {
    return this.history.length ? this.history[this.history.length - 2] : null;
  }

  public getCurrentUrl(): Subject<string> {
    return this.currentUrl$;
  }
}
