@if (!showEmptyCard) {
  <div class="flex flex-col justify-between">
    <div class="flex w-fit">
      <strong
        class="font-bold text-4xl md:text-5xl overflow-visible whitespace-nowrap">
        {{ getTitle() }}
      </strong>
      @if (isOwner) {
        <app-edit-button
          class="pl-4"
          [routerLink]="permissionLink"></app-edit-button>
      }
    </div>
    <div class="flex w-fit flex-col pt-4 pr-2 gap-8">
      <div class="flex gap-y-4 gap-x-16 flex-wrap">
        @for (contact of school.contacts; track contact.userId) {
          <div>
            <div class="relative p-4">
              <a [routerLink]="['/profile', contact.userId]">
                <app-round-image
                  [title]="contact.name"
                  [subtitle]="contact.jobFunction"
                  [description]="contact.phone"
                  [src]="getImageUrl(contact.userId)"></app-round-image>
              </a>
              <button
                mat-icon-button
                class="absolute -right-10 top-1.5 fab-button-color scale-[0.85] self-center cursor-pointer"
                (click)="openChat(contact.userId)">
                <mat-icon class="material-symbols-rounded scale-[1.2]">
                  forum
                </mat-icon>
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}

@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Ansprechpersonen"
    subtitle="Kontaktdaten verwalten"
    description="Füge hier die schulischen Ansprechpersonen hinzu."
    [link]="permissionLink"
    [fullWidthAlways]="true"></app-empty-card>
}
